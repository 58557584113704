import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UsuarioContext } from "../context/AuthContext";
//import { FirestoreContext } from "../context/FirestoreContext";
import { Container, Row, Col } from "react-bootstrap";
import { TextField, Button } from "@mui/material";
import logo from "../assets/logo.png";
import Swal from 'sweetalert2'
import { Backdrop, CircularProgress } from "@mui/material";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

const IniciarSesion = () => {
 //console.log("IniciarSesion => se ejecutó lo primero");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mostrarBackdrop, setMostrarBackdrop] = useState(false);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  //console.log(error);

  //console.log(error);
  const navigate = useNavigate();

  const { signIn } = UsuarioContext();
/*
  useLayoutEffect(()=>{
    
  },[])
  */


  useEffect(() => {
    console.log("IniciarSesion => se ejecutó useEffect");
    onAuthStateChanged(auth, async (currentUser) => {  
      if(currentUser!=null){
        //setMostrarBackdrop(false);
      }      
    });
    //console.log(user)
  },[]);


  const iniciarSesion = async (e) => {

    e.preventDefault();
    setMostrarBackdrop(true);
    try {
      const rta = await signIn(email, password);
      if (rta) {        
        navigate("/cuenta");
      }
      setMostrarBackdrop(false);
    } catch (e) {
      setMostrarBackdrop(false);
      console.log(e.message);
      switch (e.code) {
        case "auth/wrong-password":
          Toast.fire({
            icon: 'error',
            title: 'Contraseña incorrecta'
          })
          break;

        case "auth/invalid-email":
          //sweet alert aca
          break;

        case "auth/user-not-found":
          //sweet alert aca
          break;
        default:
      }
    }

    /*
    console.clear();
    console.log("IniciarSesion=> se intentará iniciar sesin con "+email+" contra "+password)
    e.preventDefault();
    try {
      signIn(email, password);
      navigate("/cuenta");
    } catch (e) {
      //toast("Se agrego un link",{type:"success", autoClose:2500})
      console.log(e.message);
      switch (e.code) {
        case "auth/wrong-password":
          Toast.fire({
            icon: 'error',
            title: 'Contraseña incorrecta'
          })
          break;

        case "auth/invalid-email":
          //sweet alert aca
          break;

        case "auth/user-not-found":
          //sweet alert aca
          break;
        default:
      }
    }
    */
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={mostrarBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container fluid className="mt-3 px-5 text-center">
        <Row>
          <Col>
            <img src={logo} alt="Logo" width="650" height="200" className='img-fluid shadow-4' />
            <br />
            <br />
            <br />
            <h2>ADMON</h2>
          </Col>
        </Row>
        <form onSubmit={iniciarSesion}>
          <Row>
            <Col>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                name="nombre"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              //onChange={(e) => setProveedor(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <TextField
                fullWidth
                label="Contraseña"
                variant="outlined"
                name="nombre"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              //onChange={(e) => setProveedor(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Button variant="contained" type="submit">
              INICIAR SESION
            </Button>
          </Row>
        </form>
      </Container>
    </>
  );
};

export default IniciarSesion;