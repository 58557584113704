import React from "react";
import { createContext, useContext, useEffect /*useState*/ } from "react";
import {
  doc, setDoc, deleteDoc, collection, updateDoc, getDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
const firestoreContext = createContext();

export const FirestoreContextProvider = ({ children }) => {
  useEffect(() => {
    //console.log("FirestoreContext.js se ejecuto el useEffect")
  }, []);

  //const [user, setUser] = useState({});

  /*CONSULTAR*******************************************************/
  const validarExistenciaDocPorId = async (coleccion, id) => {
    console.log(
      "se validará si existe o no el doc con ID: " +
      id +
      " en la coleccion " +
      coleccion
    );
    let idString = id.toString();
    const docRef = doc(db, coleccion, idString);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  };

  const consUltimoDoc = async (coleccion) => {
    console.log(
      "Se consultará el ultimo documento de la coleccion:" + coleccion
    );
    const q = query(collection(db, coleccion), limit(1), orderBy("id", "desc"));
    const el = await getDocs(q);
    //console.log(el.docs);

    //aqui realmente hay 1 solo documento, debido a que limitamos a 1
    let ultimoDocumento = {};
    el.docs.forEach((doc) => {
      ultimoDocumento = doc.data();
    });
    //console.log("ultimo doc",ultimoDocumento)
    return ultimoDocumento;
  };

  const consDocsEntreFechasMas2CamposFechaDesc = async (coleccion, fechaDesde, fechaHasta, campo1, valor1, campo2, valor2) => {
    console.log("se consultara la coleccion " + coleccion + " desde la fecha " + fechaDesde + " hasta " + fechaHasta + " con campo " + campo1 + "=" + valor1 + " y " + campo2 + "=" + valor2);
    let consulta = query(collection(db, coleccion),
      where("fecha", ">=", fechaDesde),
      where("fecha", "<=", fechaHasta),
      where(campo1, "==", valor1),
      where(campo2, "==", valor2),
      orderBy("fecha", "desc"));

    let snapshot = await getDocs(consulta);
    if (snapshot.size > 0) {
      let arrDocs = [];
      snapshot.docs.forEach((doc) => {
        //console.log(doc.data())
        arrDocs.push(doc.data())
      })
      //console.log(arrDocs)
      return arrDocs;
    } else { return []; }
  }

  const consDocsEntreFechasMas1CampoFechaDesc = async (coleccion, fechaDesde, fechaHasta, campo1, valor1) => {
    console.log("se consultara la coleccion " + coleccion + " desde la fecha " + fechaDesde + " hasta " + fechaHasta + " con campo " + campo1 + "=" + valor1);
    let consulta = query(collection(db, coleccion),
      where("fecha", ">=", fechaDesde),
      where("fecha", "<=", fechaHasta),
      where(campo1, "==", valor1),
      orderBy("fecha", "desc"));

    let snapshot = await getDocs(consulta);
    if (snapshot.size > 0) {
      let arrDocs = [];
      snapshot.docs.forEach((doc) => {
        //console.log(doc.data())
        arrDocs.push(doc.data())
      })
      //console.log(arrDocs)
      return arrDocs;
    } else { return []; }
  }

  const consDocsPorDosCampos = async (coleccion, campo1, valor1, campo2, valor2) => {
    //console.log("Se consultaran los docs que coincidan con "+campo1+"=>"+valor1+" y "+campo2+"=>"+valor2)
    const q = query(
      collection(db, coleccion),
      where(campo1, "==", valor1),
      where(campo2, "==", valor2)
    );
    const querySnapshot = await getDocs(q);
    //console.log(querySnapshot);    
    if (querySnapshot.size > 0) {
      let docs = [];
      querySnapshot.forEach((doc) => {
        docs.push(doc.data());
      });
      return docs;
    } else { return [] }


  };

  const consultarColeccion = async (coleccion) => {
    console.log(
      "FirestoreContext.js => se consultará la coleccion " + coleccion
    );
    return "listo";
  };

  const consultarDocPorId = async (coleccion, id) => {
    console.log("FirestoreContext.js => se consultará el documento de ID " + id + " en la coleccion " + coleccion);

    const docRef = doc(db, coleccion, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return "el documento no existe";
    }
  };


  /*CREAR ************************************************************/
  const crearDocIdPropio = async (coleccion, id, datos) => {
    let rta = await validarExistenciaDocPorId(coleccion, id);
    switch (rta) {
      case true:
        return "el documento ya existe";
        break;
      case false:
        let idString = id.toString();
        datos.creado = serverTimestamp();
        await setDoc(doc(db, coleccion, idString), datos);
        return "documento creado";
    }
  };

  /*EDITAR*************************************************************/
  const editarDocPorIDconMerge = async (coleccion, id, objDatos) => {
    let rta = await validarExistenciaDocPorId(coleccion, id);
    switch (rta) {
      case false:
        console.log("el documento a editar no existe");
        return "el documento a editar no existe";
        break;

      case true:
        let idString = id.toString();
        const docRef = doc(db, coleccion, idString);
        objDatos.modificado = serverTimestamp();
        setDoc(docRef, objDatos, { merge: true });
        return "documento editado";
    }
  };

  const agregDatoADoc = async (coleccion, idDoc, objDatos) => {
    const docRef = doc(db, coleccion, idDoc);
    await updateDoc(docRef, objDatos);
    return "documento editado";
  };

  /*ELIMINAR **************************************************/
  const eliminarDocPorId = async function (coleccion, id) {
    console.log("se eliminará el documento de id " + id + " de la coleccion " + coleccion)
    const idString = id.toString();
    await deleteDoc(doc(db, coleccion, idString));
    return true;
  }

  return (
    <firestoreContext.Provider
      value={{
        consultarColeccion,
        consultarDocPorId,
        consDocsPorDosCampos,
        crearDocIdPropio,
        editarDocPorIDconMerge,
        agregDatoADoc,
        consUltimoDoc,
        consDocsEntreFechasMas2CamposFechaDesc,
        consDocsEntreFechasMas1CampoFechaDesc,
        eliminarDocPorId,
      }}
    >
      {children}
    </firestoreContext.Provider>
  );
};

export const FirestoreContext = () => {
  return useContext(firestoreContext);
};
