import React, {/*useState,*/ useEffect}  from 'react';
//import { Navigate } from 'react-router-dom';
//import { UsuarioContext } from '../context/AuthContext';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
//import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";


const ProtectedRoute = ({ children }) => {

  const navigate = useNavigate();
  //const [mostrarBackdrop, setMostrarBackdrop] = useState(false);

 


  //const { user } = UsuarioContext();
  //console.log("protected route =>"+user.email)
  /*
  if(user.email=="none") {
    return <Navigate to='/login'/>;
  }
  */
  useEffect(()=>{   
    
    onAuthStateChanged(auth, async (currentUser) => {      
      //setMostrarBackdrop(true);
      if (currentUser != null) {
        
        //console.log("App.Js, usuario logueado => " + currentUser.email);
        //console.log("ProtectedRoute.js usuario logueado =>"+currentUser.email);
        //setMostrarBackdrop(false);
        //const docRef = doc(db, "usuarios", currentUser.email);
        //const docSnap = await getDoc(docRef);
        //console.log(docSnap.data().permisos)
        //setUser({ email: currentUser.email, permisos: docSnap.data().permisos });
        //navigate('/cuenta');
      }

      if (currentUser == null) {
        //console.log("ProtectedRouter.js => No hay usuario logueado");
        navigate("/login");
        //setMostrarBackdrop(false);
        //return <Navigate to='/login'/>;
        
        //const docRef = doc(db, "datosDefecto", "permisosUsuario");
        //const docSnap = await getDoc(docRef);
        //console.log(docSnap.data())
        //setUser({ email: "none", permisos: docSnap.data() });
        //navigate('/login');
      }
    });   
    
  },[])

  return (
    children);
  
};

export default ProtectedRoute;
