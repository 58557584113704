import { db } from "../firebase";
import { doc, setDoc, deleteDoc, collection, getDoc, getDocs, query, where, serverTimestamp, limit, orderBy, } from "firebase/firestore";


const consultarArrayObjsDeDoc = async (coleccion, idDoc) => {
    console.log("se consultará el documento de id " + idDoc + " en la coleccion " + coleccion + ", se iterará las claves y se formará un array de objetos con ellos")
    let ArrObjs = [];
    const docRef = doc(db, coleccion, idDoc);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        //console.log(docSnap.data())
        for (let clave in docSnap.data()) {
            let objCliente = docSnap.data()[clave];
            ArrObjs.push({
                id: objCliente.id,
                nombre: objCliente.nombre,
                usuario: objCliente.usuario
            });
        }
        //console.log("seteando el estado "+datos.estado+" con los datos",ArrObjs);
        return ArrObjs;
    } else {
        return ArrObjs
    }
}


export { consultarArrayObjsDeDoc }