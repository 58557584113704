import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { doc, getDoc  } from "firebase/firestore";
import { db } from "../firebase";

const datosContext = createContext();
//const { consultarColeccion } = FirestoreContext("");
export const DatosContextProvider = ({ children }) => {
  useEffect(() => {
    //console.log("DatosContext.js se ejecuto el useEffect")
  }, []);

  const [arrTiposDocs, setArrTiposDocs] = useState([]);
  const [arrTiposConcepto, setTiposConcepto] = useState([]);
  const [arrClientes, setClientes] = useState([]);
  

  const consTiposDocs = async () => {
    console.log("Consultando tipos de documentos...")
    const docRef = doc(db, "datosDefecto", "tiposDocumento");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //console.log(docSnap.data());
      let array = [];
      for (let clave in docSnap.data()) {
        let objTipoDoc = docSnap.data()[clave];
        array.push({
          id: objTipoDoc.id,
          nombre: objTipoDoc.nombre,
        });
      }
      console.log("tipos de documento",array)
      setArrTiposDocs(array);
    } else {
      alert("No esta el documento tiposDocumento en la coleccion datosDefecto")
    }
  };

  const consTiposConcepto = async () => {
    //console.log("Consultando tipos de conceptos...")
    const docRef = doc(db, "datosDefecto", "tiposConcepto");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //console.log(docSnap.data());
      let array = [];
      for (let clave in docSnap.data()) {
        let objTipoConcepto = docSnap.data()[clave];
        array.push({
          id: objTipoConcepto.id,
          nombre: objTipoConcepto.nombre,
        });
      }
      //console.log("tipos de concepto",array)
      setTiposConcepto(array);
    } else {
      alert("No esta el documento tiposConcepto en la coleccion datosDefecto")
    }
  };


  const consClientes = async () => {
    //console.log("Consultando tipos de conceptos...")
    const docRef = doc(db, "datosDefecto", "clientes");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //console.log(docSnap.data());
      let array = [];
      for (let clave in docSnap.data()) {
        let objCliente = docSnap.data()[clave];
        array.push({
          id: objCliente.id,
          nombre: objCliente.nombre,
          usuario: objCliente.usuario
        });
      }
      //console.log("tipos de concepto",array)
      setClientes(array);
    } else {
      alert("No esta el documento clientes en la coleccion datosDefecto")
    }
  };

  return (
    <datosContext.Provider
      value={{
        consTiposDocs,   
        arrTiposDocs,
        consTiposConcepto, 
        arrTiposConcepto,
        consClientes,  
        arrClientes
      }}
    >
      {children}
    </datosContext.Provider>
  );
};

export const DatosContext = () => {
  return useContext(datosContext);
};
