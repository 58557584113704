// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const firebaseConfig = {
    apiKey: "AIzaSyA9FPGrzejsiyxyujU7xiaS-u7PI3tOlr4",  
    authDomain: "soluciones-tributarias-7d943.firebaseapp.com",  
    projectId: "soluciones-tributarias-7d943",  
    storageBucket: "soluciones-tributarias-7d943.appspot.com",  
    messagingSenderId: "14925828864",
    appId: "1:14925828864:web:3b333220fd9248e30c59e5"  
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);


export default app


//const analytics = getAnalytics(app);