import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UsuarioContext } from '../context/AuthContext';
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

const Cuenta = () => {
  const { user, setUser, logout } = UsuarioContext();
  console.log(user.email);
  const [mostrarBackdrop, setMostrarBackdrop] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setMostrarBackdrop(true)
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser != null) {
        console.log(currentUser.email);
        setUser({ ...user, email: currentUser.email });
        setMostrarBackdrop(false)
      }
      if (currentUser == null) {
        setMostrarBackdrop(false)
        console.log("No hay usuario logueado");
        //const docRef = doc(db, "datosDefecto", "permisosUsuario");
        //const docSnap = await getDoc(docRef);
        //console.log(docSnap.data())
        //etUser({ email: "none", permisos: docSnap.data() });
        //navigate('/login');
      }
    });
  }, [])


  const cerrarSesion = async () => {
    //setMostrarBackdrop(true)
    try {
      const rta = await logout();
      if (rta) {
        navigate('/login');
        //setMostrarBackdrop(false);
      }
      //sessionStorage.removeItem('Auth Token');      
      console.log('You are logged out')
    } catch (e) {
      //setMostrarBackdrop(false)
      console.log(e.message);
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={mostrarBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className='max-w-[600px] mx-auto my-16 p-4'>
        <h1 className='text-2xl font-bold py-4'>Cuenta</h1>
        {/*<p>email: {user.email}</p>*/}
        <p>email: {user.email}</p>
        <br />
        {/*crear concepto: { user.permisos.conceptos.crear ? <h1>verdadero </h1>: <h1>falso </h1>}*/}
        <Button variant="contained" onClick={cerrarSesion}>
          Cerrar sesión
        </Button>
      </div>
    </>
  );
};

export default Cuenta;