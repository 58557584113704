import React, { useState, useEffect } from "react";
import { FirestoreContext } from "../context/FirestoreContext";
import { UsuarioContext } from "../context/AuthContext";
import { Row, Col, Container, InputGroup } from "react-bootstrap";
import { DatosContext } from "../context/DatosContext";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Backdrop, CircularProgress } from "@mui/material";
import { Button, TextField, Autocomplete } from "@mui/material";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Table, TableBody, TableCell, TableContainer, TableHead, Typography, TableRow } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { getStorage, deleteObject, ref } from "firebase/storage";

//import storage from "../firebase"
//import { useNavigate } from "react-router-dom";

const ConsultarConceptos = () => {
  const storage = getStorage();
  const { user, setUser } = UsuarioContext();
  const { consDocsEntreFechasMas2CamposFechaDesc, consDocsEntreFechasMas1CampoFechaDesc, consultarDocPorId, eliminarDocPorId } = FirestoreContext("");
  const [tipoConceptoEscogido, setTipoConceptoEscogido] = useState({ id: "Seleccione", nombre: "Seleccione" });
  const [mostrarBackdrop, setMostrarBackdrop] = useState(false);
  const [conceptos, setConceptos] = useState([]);

  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      setMostrarBackdrop(true);
      if (currentUser != null) {
        const rta = await consultarDocPorId("usuarios", currentUser.email)
        setUser({ email: currentUser.email, permisos: rta.permisos });
        setMostrarBackdrop(false);
      }
    });

    setMostrarBackdrop(true);
    const traerDatos = async () => {
      await consTiposConcepto();
      setMostrarBackdrop(false);
    };

    traerDatos();
    //console.log("proveedores.jsx => se ejecutó useEffect");
    /* TIPOS DOCUMENTOS */
    //console.log(arrTiposDocumentos)
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const { arrTiposConcepto, consTiposConcepto } = DatosContext();
  const [fechasConsulta, setFechasConsulta] = useState({
    desde: dayjs().format("YYYY-MM-DD"),
    hasta: dayjs().format("YYYY-MM-DD"),
  });


  const consultarConceptos = async (e) => {
    e.preventDefault();
    console.log("usuario logueado", user.email);

    if (tipoConceptoEscogido.id == "Seleccione") { Toast.fire({ icon: 'error', title: 'Debe seleccionar el tipo de concepto' }); return; }
    Toast.fire({ icon: 'info', title: 'Consultando...', timer: false })
    Toast.showLoading();
    let arrConceptos;
    if (user.permisos.admin) {
      console.log("si es admin")
      arrConceptos = await consDocsEntreFechasMas1CampoFechaDesc("conceptos", fechasConsulta.desde, fechasConsulta.hasta, "idTipoConcepto", tipoConceptoEscogido.id);
    } else {
      arrConceptos = await consDocsEntreFechasMas2CamposFechaDesc("conceptos", fechasConsulta.desde, fechasConsulta.hasta, "idTipoConcepto", tipoConceptoEscogido.id, "cliente.usuario", user.email);
    }
    if (arrConceptos.length > 0) {
      Toast.close();
      setConceptos(arrConceptos);
    } else {
      Toast.fire({ icon: "error", title: "No hay conceptos generados del tipo " + tipoConceptoEscogido.nombre + " en el rango de fechas seleccionado" });
    }
  };



  const TooltipDescripcion = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({ [`& .${tooltipClasses.tooltip}`]: { maxWidth: 500, } });



  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={mostrarBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container fluid className="mt-3 px-5 text-center">
        <Row>
          <Col>
            <h2>CONSULTAR CONCEPTOS</h2>
          </Col>
        </Row>
        {/*
        ESCOGIDO
        <br />
        {tipoConceptoEscogido.id} - {tipoConceptoEscogido.nombre}
        <br />
        CONCEPTO
        <br/>        
        {concepto.nombre}-{concepto.idTipoConcepto}
        */}
        <form onSubmit={consultarConceptos}>
          <Row>
            <Col>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="Desde"
                  inputFormat={"DD/MMM/YYYY"}
                  value={fechasConsulta.desde}
                  //disablePast
                  onChange={(f) => {
                    let fecha = dayjs(new Date(f)).format("YYYY-MM-DD");
                    console.log(fecha);
                    setFechasConsulta({ ...fechasConsulta, desde: fecha });
                  }}
                  closeOnSelect
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Col>
            <Col>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="Hasta"
                  inputFormat={"DD/MMM/YYYY"}
                  value={fechasConsulta.hasta}
                  //disablePast
                  onChange={(f) => {
                    let fecha = dayjs(new Date(f)).format("YYYY-MM-DD");
                    console.log(fecha);
                    setFechasConsulta({ ...fechasConsulta, hasta: fecha });
                  }}
                  closeOnSelect
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Autocomplete
                disableClearable
                disablePortal
                //disabled={habilitacion.idTipoDocumento}
                isOptionEqualToValue={(option, value) =>
                  "Seleccione" === "Seleccione"
                }
                value={tipoConceptoEscogido}
                renderInput={(params) => (
                  <TextField {...params} label="Tipo de concepto" />
                )}
                //id="combo-box-demo"
                getOptionLabel={(options) => options.nombre}
                options={arrTiposConcepto}
                //name = "tipoDocumento"
                onChange={(event, tipoC) => {
                  //console.log(tc)
                  setTipoConceptoEscogido({
                    id: tipoC.id,
                    nombre: tipoC.nombre,
                  });
                }}
                sx={{ width: "fullWidth", listbox: { background: "#ffffff" } }}
              />
            </Col>
          </Row>
          <br />
          {conceptos.length == 0 && (
            <Row>
              <Button variant="contained" type="submit">
                <i className="material-icons">search</i>CONSULTAR
              </Button>
            </Row>
          )}

        </form>
        {conceptos.length > 0 && (
          <Row>
            <Button variant="contained" onClick={() => {
              setTipoConceptoEscogido({ id: "Seleccione", nombre: "Seleccione" })
              setConceptos([]);
              setFechasConsulta({ desde: dayjs().format("YYYY-MM-DD"), hasta: dayjs().format("YYYY-MM-DD") })
            }}>
              <ReplayOutlinedIcon />
              REALIZAR OTRA CONSULTA
            </Button>
          </Row>
        )}

        <br />
        {conceptos.length > 0 && (
          <Row>
            <Typography variant="h5">{"Total conceptos: " + conceptos.length}</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">FECHA</TableCell>
                    {user.permisos.admin && <TableCell align="center">CLIENTE</TableCell>}
                    <TableCell align="center">TIPO</TableCell>
                    <TableCell align="center">DESCRIPCION</TableCell>
                    <TableCell align="center">PDF</TableCell>
                    {user.permisos.conceptos.eliminar && <TableCell align="center">ELIMINAR</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {conceptos.map((concepto, index) => (
                    <TableRow key={concepto.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell align="center">{concepto.id}</TableCell>
                      <TableCell align="center">{concepto.fecha}</TableCell>
                      {user.permisos.admin && <TableCell align="center">{concepto.cliente.nombre}</TableCell>}
                      <TableCell align="center">{arrTiposConcepto.find(({ id }) => id === concepto.idTipoConcepto).nombre}</TableCell>
                      <TableCell align="center">
                        <TooltipDescripcion title={concepto.descripcion} followCursor>
                          <TextsmsOutlinedIcon />
                        </TooltipDescripcion>
                      </TableCell>
                      <TableCell align="center">
                        <GetAppOutlinedIcon sx={{ cursor: "pointer", "&:hover": { color: "green" } }} onClick={() => { window.open(concepto.url, "_blank"); }} />
                      </TableCell>
                      {user.permisos.conceptos.eliminar && <TableCell align="center">
                        <DeleteOutlineOutlinedIcon sx={{ cursor: "pointer", "&:hover": { color: "red" } }} onClick={async () => {
                          //console.log("index: " + index + "=> id: " + concepto.id);
                          //console.log(user.email)
                          //console.log(user.permisos)
                          setMostrarBackdrop(true);
                          const rta = await eliminarDocPorId("conceptos", concepto.id);
                          if (rta) {
                            const nuevoArrConceptos = [...conceptos];
                            const archivoRef = ref(storage, "conceptos/CONCEPTO-"+concepto.id+"-"+concepto.fecha+".pdf");
                            // Delete the file
                            deleteObject(archivoRef).then(() => {                              
                              console.log("archivo eliminado");
                              nuevoArrConceptos.splice(index, 1);
                              setConceptos(nuevoArrConceptos);    
                              setMostrarBackdrop(false);                          
                            }).catch((error) => {
                              // Uh-oh, an error occurred!
                              console.log(error.code);
                              setMostrarBackdrop(false);
                              alert("archivo a eliminar no existe");
                            });
                          }
                        }} />
                      </TableCell>}

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Row>
        )}
      </Container>
    </>
  );
};

export default ConsultarConceptos;
