import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Backdrop, CircularProgress } from "@mui/material";


//import { useNavigate } from "react-router-dom";

const UserContext = createContext();
export const AuthContextProvider = ({ children }) => {

  const [mostrarBackdrop, setMostrarBackdrop] = useState(false);

  //const navigate = useNavigate();
  /*
  let usuarioDefecto = {
    email: "none",
    permisos: {
      conceptos: {
        crear: false,
        consultar: false,
        editar: false,
        eliminar: false
      }
    }
  }*/

  /*
  const docRef = doc(db, "usuarios", "director@paneladulcesita.com");
  getDoc(docRef).then((docSnap)=>{
    console.log(docSnap.data().permisos);
    setUser({...user, permisos:docSnap.data().permisos});
  });*/

  //let permisosDefecto = {}

  let permisosDefecto = {
    admin:false,
    conceptos: {
      crear: false,
      consultar: false,
      editar: false,
      eliminar: false
    }
  }
  //console.log("permisos defecto", permisosDefecto);

  const [user, setUser] = useState({ email: "none", permisos: permisosDefecto });

  useEffect(() => {
    /*
    const traerPermisosDefecto = async () => {
      const docRef = doc(db, "datosDefecto", "permisosUsuario");
      const docSnap = await getDoc(docRef);
      console.log(docSnap.data())
    }
    traerPermisosDefecto();
    */

    //console.log("AuthContext => useEffect");
    onAuthStateChanged(auth, async (currentUser) => {


      if (currentUser != null) {

        //console.log("AuthContext, usuario logueado => " + currentUser.email);
        //console.log(currentUser);
        //const docRef = doc(db, "usuarios", currentUser.email);
        //const docSnap = await getDoc(docRef);
        //console.log(docSnap.data().permisos)
        //setUser({ email: currentUser.email, permisos: permisosDefecto });
        //navigate('/cuenta');
      }

      if (currentUser == null) {
        //console.log("No hay usuario logueado");
        //const docRef = doc(db, "datosDefecto", "permisosUsuario");
        //const docSnap = await getDoc(docRef);
        //console.log(docSnap.data())
        //etUser({ email: "none", permisos: docSnap.data() });
        //navigate('/login');
      }
    });

    /*onAuthStateChanged(auth, (currentUser) => {    
      if(currentUser!=null){
        console.log("AuthContext, usuario logueado => "+currentUser.email);
        setUser({email:currentUser.email, permisos:{conceptos:{crear:false,
          editar:false,
          eliminar:false,
          consultar:false
        }}});
        const docRef = doc(db, "usuarios", currentUser.email);
        getDoc(docRef).then((docSnap)=>{
          if (docSnap.exists()) {
            console.log("permisos en firestore si existen, colocandolos en el state")
            setUser({email:currentUser.email, permisos:docSnap.data().permisos});
            //navigate("/cuenta");
          } else {
            alert("no existe el documento "+currentUser.email+" en la coleccion usuarios, cerrando sesión...")
            signOut(auth)
          }
        });
      }
      if(currentUser==null){
        console.log("No hay usuario logueado");
        const docRef = doc(db, "datosDefecto", "permisosUsuario");
        getDoc(docRef).then((docSnap)=>{
          if (docSnap.exists()) {
            console.log("colocando en el state permisos por defecto traidos de firestore")
            setUser({email:null, permisos:docSnap.data()});
          }
        });
      }
    });
    */
  }, []);

  //const navigate = useNavigate();
  const signIn = async (email, password) => {
    const rta = await signInWithEmailAndPassword(auth, email, password);
    if (rta.user.email == email) {
      const docRef = doc(db, "usuarios", rta.user.email);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        //console.log(docSnap.data().permisos)
        setUser({ email: rta.user.email, permisos: docSnap.data().permisos });
        return true;
      }else{
        console.log("faltan permisos")
        alert("No existe el usuario "+email+" en la coleccion usuarios de firestore, cerrando sesión...")
        logout();
        return;
      }
    }

    /*
    console.log("AuthContext=> se recibio "+email+", contra "+password)
    try{
      signInWithEmailAndPassword(auth, email, password).then((response)=>{
        sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
      });
      
    }catch(e){
      console.log(e)
    }
    */

  };

  const logout = async () => {
    setMostrarBackdrop(true);

    const docRef = doc(db, "datosDefecto", "permisosUsuario");
    const docSnap = await getDoc(docRef);
    //console.log(docSnap.data());
    setUser({ email: "none", permisos: docSnap.data() })
    await signOut(auth);
    setMostrarBackdrop(false);

    return true;
  };



  return (
    <UserContext.Provider value={{ user, setUser, logout, signIn }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={mostrarBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </UserContext.Provider>
  );
};

export const UsuarioContext = () => {
  return useContext(UserContext);
};
