import React, { useState, useEffect } from "react";
import { FirestoreContext } from "../context/FirestoreContext";
import { Row, Col, Container, Form } from "react-bootstrap";
import { DatosContext } from "../context/DatosContext";
import Swal from "sweetalert2";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  getStorage, ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import {
  Backdrop,
  CircularProgress,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import { urlApiBackend } from "../apiURL";
import { consultarArrayObjsDeDoc } from '../scripts/firestore.consultar.js'

//import storage from "../firebase"

const Cuenta = () => {
  const storage = getStorage();

  const [clientes, setClientes] = useState([])

  useEffect(() => {
    //console.log(concepto);
    setMostrarBackdrop(true);
    const traerDatos = async () => {
      await consTiposConcepto();

      const arrClientes = await consultarArrayObjsDeDoc("datosDefecto", "clientes");
      console.log(arrClientes)
      setClientes(arrClientes);


      setMostrarBackdrop(false);
    };
    traerDatos();
    //console.log("proveedores.jsx => se ejecutó useEffect");
    /* TIPOS DOCUMENTOS */
    //console.log(arrTiposDocumentos)
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const { arrTiposConcepto, consTiposConcepto } = DatosContext();

  const [concepto, setConcepto] = useState({
    id: "",
    fecha: "",
    descripcion: "Ninguno",
    idTipoConcepto: 0,
    cliente: {}
  });
  const [tipoConceptoEscogido, setTipoConceptoEscogido] = useState({ id: "Seleccione", nombre: "Seleccione", });
  const [clienteEscogido, setClienteEscogido] = useState({ id: "Seleccione", nombre: "Seleccione", usuario: '' });
  const [fechaEscogida, setFechaEscogida] = useState(dayjs().format("YYYY-MM-DD"));
  const [mostrarBackdrop, setMostrarBackdrop] = useState(false);
  const [file, setFile] = useState(null);

  const { consUltimoDoc, crearDocIdPropio } = FirestoreContext("");

  const crearConcepto = async (e) => {
    e.preventDefault();
    console.clear();

    if (tipoConceptoEscogido.id === "Seleccione") { Toast.fire({ icon: "error", title: "🤨Debe Seleccionar el tipo de concepto" }); return; }
    if (clienteEscogido.id === "Seleccione") { Toast.fire({ icon: "error", title: "🤨Debe Seleccionar el cliente a quien va dirigido el concepto" }); return; }
    console.log(concepto)



    if (file === null) { Toast.fire({ icon: "error", title: "🤨Debe adjuntar el archivo PDF del concepto" }); return; }

    Toast.fire({ icon: "info", title: "🤔Guardando concepto...", timer: false });
    Toast.showLoading();

    const objUltimoConcepto = await consUltimoDoc("conceptos");

    if (!Number.isInteger(objUltimoConcepto.id)) { Toast.close(); alert("Error, el id del ultimo concepto guardado no es entero, no se puede guardar este concepto"); return; }

    console.log("ultimo concepto", concepto);
    concepto.id = objUltimoConcepto.id + 1;
    //esto ya ocurre en el mobile picker, pero solo cundo hay cambios en el, suponinedo que el usuario no haga cambios
    //o sea que deje la fecha de hoy ahi en el calendario y le de guardar, no se guardaria en el concepto como una clave
    //por eso la agrego aca
    concepto.fecha = fechaEscogida;

    const storageRef = ref(storage, "conceptos/CONCEPTO-" + concepto.id + "-" + concepto.fecha + ".pdf");
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed", (snapshot) => {
      const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      // update progress
      console.log(percent + "%");
    }, (err) => console.log(err), () => {
      console.log("se subio realmente");
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        concepto.url = url;
        console.log("concepto a guardar", concepto);
        crearDocIdPropio("conceptos", concepto.id, concepto).then(async (rta) => {
          switch (rta) {
            case "documento creado":
              Toast.fire({ icon: "success", title: "🥳 Concepto creado" });

              //se envia un email avisando que se subio el concepto
              let cuerpoCorreo = `<h3>Se ha generado un nuevo concepto de tipo ${tipoConceptoEscogido.nombre}</h3>
              <p>Para descargar el PDF por favor inicie sesión en <a href='https://admon.solucionestributarias.co'>admon.solucionestributarias.co</a></p>
              <p>Menú conceptos => Consultar</p>
              <p>con su usuario ${concepto.cliente.usuario}</p>    
              </br>
              <p>Descripcion del concepto: ${concepto.descripcion}</p>  `

              let datosCorreo = { emailDestinatario: concepto.cliente.usuario, asunto: "Nuevo concepto generado de tipo " + tipoConceptoEscogido.nombre, cuerpoHtml: cuerpoCorreo }
              let url = urlApiBackend + '/enviarEmail'
              console.log(url)
              const rtaJson = await fetch(url, { method: 'POST', cache: 'no-cache', body: JSON.stringify(datosCorreo), headers: { "Content-Type": "application/json" } })
              if (rtaJson.ok) {
                console.info("correo notificando al cliente enviado correctamente");
              } else {
                let errorRta = await rtaJson.json()
                alert("Favor avisar a sistemas, si se generó el concepto pero no se notificó por correo, codigo de error desde el servidor " + errorRta.code + ", mensaje: " + errorRta.message)
              }

              setTipoConceptoEscogido({ id: "Seleccione", nombre: "Seleccione", });
              setClienteEscogido({ id: "Seleccione", nombre: "Seleccione", usuario: '' });
              setConcepto({ id: "", descripcion: "Ninguno", idTipoConcepto: "" });
              setFile(null)
              //Toast.close();
              break;
            default:
          }
        });
        //let objDatos = { id: concepto.id, descripcion: concepto.descripcion, idTipoConcepto: tipoConceptoEscogido.id };
      });
    });
  };

  const cambiosInput = (e) => {
    //console.log("e.target.name", e.target.name);
    //console.log("e.target.value", e.target.value);
    const { name, value } = e.target;
    //console.log(name, value);
    setConcepto({ ...concepto, [name]: value });
    //console.log(concepto);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={mostrarBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container fluid className="mt-3 px-5 text-center">
        <Row>
          <Col>
            <h2>SUBIR CONCEPTO</h2>
          </Col>
        </Row>
        {/*
        ESCOGIDO
        <br />
        {tipoConceptoEscogido.id} - {tipoConceptoEscogido.nombre}
        <br />
        CONCEPTO
        <br/>        
        {concepto.nombre}-{concepto.idTipoConcepto}
        */}

        <form onSubmit={crearConcepto}>
          <Row>
            <Col>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="Fecha"
                  inputFormat={"DD/MMM/YYYY"}
                  value={fechaEscogida}
                  //disablePast
                  onChange={(f) => {
                    let fecha = dayjs(new Date(f)).format("YYYY-MM-DD");
                    console.log(fecha);
                    setFechaEscogida(fecha);
                    setConcepto({ ...concepto, fecha: fecha });
                    console.log(concepto);
                  }}
                  closeOnSelect
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Autocomplete
                disableClearable
                disablePortal
                //disabled={habilitacion.idTipoDocumento}
                isOptionEqualToValue={(option, value) =>
                  "Seleccione" === "Seleccione"
                }
                value={tipoConceptoEscogido}
                renderInput={(params) => (
                  <TextField {...params} label="Tipo concepto" />
                )}
                //id="combo-box-demo"
                getOptionLabel={(options) => options.nombre}
                options={arrTiposConcepto}
                //name = "tipoDocumento"
                onChange={(event, tc) => {
                  //console.log(tc)
                  setConcepto({ ...concepto, idTipoConcepto: tc.id });
                  console.log(concepto);
                  setTipoConceptoEscogido({ id: tc.id, nombre: tc.nombre });
                }}
                sx={{ width: "fullWidth", listbox: { background: "#ffffff" } }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Autocomplete
                disableClearable
                disablePortal
                //disabled={habilitacion.idTipoDocumento}
                isOptionEqualToValue={(option, value) =>
                  "Seleccione" === "Seleccione"
                }
                value={clienteEscogido}
                renderInput={(params) => (
                  <TextField {...params} label="Cliente" />
                )}
                //id="combo-box-demo"
                getOptionLabel={(options) => options.nombre}
                options={clientes}
                //name = "tipoDocumento"
                onChange={(event, cli) => {
                  //console.log(tc)
                  setClienteEscogido({ id: cli.id, nombre: cli.nombre, usuario: cli.usuario });
                  setConcepto({ ...concepto, cliente: cli });
                  console.log(concepto);

                }}
                sx={{ width: "fullWidth", listbox: { background: "#ffffff" } }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <TextField
                fullWidth
                label="Descripcion"
                variant="outlined"
                name="descripcion"
                //disabled={habilitacion.nombre}
                value={concepto.descripcion}
                onChange={cambiosInput}
              //onChange={(e) => setProveedor(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Adjunte el concepto en PDF</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event) => {
                    if (event.target.files[0].type == "application/pdf") {
                      setFile(event.target.files[0]);
                    } else {
                      Toast.fire({
                        icon: "error",
                        title: "🤨Solo se permiten archivos de tipo PDF",
                      });
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <br />

          <Row>
            <Button variant="contained" type="submit">
              <i className="material-icons">save</i>CREAR
            </Button>
          </Row>
        </form>
      </Container>
    </>
  );
};

export default Cuenta;
