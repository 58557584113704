import React, { useEffect, useState, useLayoutEffect } from "react";
import { Routes, Route /*Link*/ } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { FirestoreContextProvider } from "./context/FirestoreContext";
import { FirestoreContext } from "./context/FirestoreContext";
import { DatosContextProvider } from "./context/DatosContext";
import { UsuarioContext } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
//import {Container, Nav, Navbar, NavDropdown, /*Row, Col*/} from "react-bootstrap";
import IniciarSesion from "./components/IniciarSesion";
import Cuenta from "./components/Cuenta";
import Conceptos from "./components/Conceptos";
import ConsultarConceptos from "./components/ConsultarConceptos";
//import NotasCredito from "./components/NotasCredito";
//import Container from "@material-ui/core/";
import logo from "./assets/logo.png";
import "./index";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
//import { Backdrop, CircularProgress } from "@mui/material";
//import IniciarSesion from "./components/IniciarSesion";

const App = () => {
  //const [mostrarBackdrop, setMostrarBackdrop] = useState(true);
  const { user,logout ,setUser } = UsuarioContext();  //console.log(user);

  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      //setMostrarBackdrop(true)      
      if (currentUser != null) {
        console.log("App.Js, usuario logueado => " + currentUser.email);
        //console.log(currentUser);
        const docRef = doc(db, "usuarios", currentUser.email);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log(docSnap.data().permisos)
          setUser({ email: currentUser.email, permisos: docSnap.data().permisos });
          //setMostrarBackdrop(false)
          //navigate('/cuenta');
        } else {
          console.log("App.js => faltan permisos")
          alert("No existe el usuario " + currentUser.email + " en la coleccion usuarios de firestore, cerrando sesión...")
          logout();
          return;
        }
      }

      if (currentUser == null) {
        console.log("No hay usuario logueado");
        const docRef = doc(db, "datosDefecto", "permisosUsuario");
        const docSnap = await getDoc(docRef);
        //console.log(docSnap.data())
        setUser({ email: "none", permisos: docSnap.data() });
        //setMostrarBackdrop(false)
        //navigate('/login');
      }
    });

  }, [])
  //const navigate = useNavigate();

  return (
    <div>
      <DatosContextProvider>
        <FirestoreContextProvider>
          <AuthContextProvider>
            {/*Usuario logged {user.email}
            {user.permisos.conceptos.crear ? <h4> crear concepto verdadero</h4> : <h4>crear concepto falso</h4>}*/}
            {user.email != "none" && (
              <Navbar variant="light" bg="light" expand="lg">
                <Container fluid>
                  <Navbar.Brand>
                    <img src={logo} alt="Logo" width="100" height="40" />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="navbar-dark-example" />
                  <Navbar.Collapse id="navbar-dark-example">
                    <Nav>
                      <NavDropdown id="nav-dropdown-dark-example" title="CONCEPTOS" menuVariant="light">
                        <NavDropdown.Item href="consultarConceptos">
                          Consultar
                        </NavDropdown.Item>

                        {user.permisos.conceptos.crear && <NavDropdown.Item href="conceptos">
                          Subir
                        </NavDropdown.Item>}


                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                  {user.email != "none" && (
                    <Navbar.Text>
                      <a href="cuenta">{user.email}</a>
                    </Navbar.Text>
                  )}
                </Container>
              </Navbar>
            )}

            <Routes>
              <Route path="/" element={<IniciarSesion />} />
              <Route path="/login" element={<IniciarSesion />} />
              {/*RUTAS SIN PROTEGUER
              <Route path="/consultarConceptos" element={<ConsultarConceptos />} />
              <Route path="/cuenta" element={<Cuenta />} />
              */}

              {/*RUTAS PROTEGUIDAS POR SI SE TYPEAN EN LA BARRA DE DIRECCIONES*/}
              <Route
                path="/cuenta"
                element={
                  <ProtectedRoute>
                    {" "}
                    <Cuenta />{" "}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/conceptos"
                element={
                  <ProtectedRoute>
                    {" "}
                    <Conceptos />{" "}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/consultarConceptos"
                element={
                  <ProtectedRoute>
                    {" "}
                    <ConsultarConceptos />{" "}
                  </ProtectedRoute>
                }
              />



            </Routes>
          </AuthContextProvider>
        </FirestoreContextProvider>
      </DatosContextProvider>
    </div>
  );
};
export default App;